@import 'styles/site.sass';

.Main {
  background: $white;
  padding: 0 0 $space-l 0;

  @extend %site-links; }

.content {
  padding: $space-m 0; }
