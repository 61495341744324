@import 'styles/site.sass';

.Main {
  flex: 1;
  max-width: units(70);
  @include min($screen-m) {
    padding-right: $space-l; }

  @include max($screen-l) {
    padding-bottom: units(8); }

  @include min($screen-l) {
    padding-bottom: units(4); }

  .block {
    width: $block-width;
    height: units(2.5);
    margin-bottom: units(3);
    background: $black; }
  h1 {
    @extend %heading-xl; }

  .peter {
    margin-bottom: $space-xs;
    h4 {
      @extend %heading-m;
      @extend %bold; } } }
