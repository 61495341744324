@import 'styles/site.sass';

$transition: all 700ms cubic-bezier(0.420, 0.925, 0.000, 0.995) !important;

.FADE_enter {
  opacity: 0;
 }  // height: 0

.FADE_enterActive {
  opacity: 1;
  @include animate(0.35s); }

.FADE_exitActive {
  opacity: 0;
  @include animate(0.35s); }
//
//
//
.SCALE_enter {
  transform: scale(0.005);
  opacity: 0; }

.SCALE_enterActive {
  transform: scale(1);
  opacity: 1;
  transition: $transition; }

.SCALE_exitActive {
  transform: scale(0.005);
  opacity: 0;
  transition: $transition; }
//
//
//
.HEIGHT_enter {
  opacity: 0;
  transform: translateY(-50%); }

.HEIGHT_enterActive {
  opacity: 1;
  transform: translateY(0);
  @include animate(0.3s); }

.HEIGHT_exitActive {
  opacity: 0;
  transform: translateY(-50%);
  @include animate(0.3s); }
//
//
//
.RIGHT_enter {
  transform: translateX(100%); }

.RIGHT_enterActive {
  transform: translateX(0);
  transition: $transition; }

.RIGHT_exitActive {
  transform: translateX(100%);
  transition: $transition; }
//
//
//
.BOTTOM_enter {
  transform: translateY(100%); }

.BOTTOM_enterActive {
  transform: translateY(0);
  transition: $transition; }

.BOTTOM_exitActive {
  transform: translateY(100%);
  transition: $transition; }
//
//
//
.LEFT_enter {
  transform: translateX(-100%); }

.LEFT_enterActive {
  transform: translateX(0);
  transition: $transition; }

.LEFT_exitActive {
  transform: translateX(-100%);
  transition: $transition; }
//
//
//
.TOP_enter {
  transform: translateY(-100%); }

.TOP_enterActive {
  transform: translateY(0);
  transition: $transition; }

.TOP_exitActive {
  pointer-events: none;
  transform: translateY(-100%);
  transition: $transition; }
