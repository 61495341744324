@import 'styles/site.sass';

.Main {

  li {
    &.internal {
      &+.link {
        padding-top: units(2);
        margin-top: units(2);
        border-top: 1px solid $grey-300; } }


    &.link {
      a {
        &:before {
          content: "→";
          margin-right: units(1); }
        &:after {
          display: none !important; } } }

    a {
      display: block;
      position: relative;
      @extend %body-m;
      padding: units(1) 0;
      @extend %links;
      display: flex;
      align-items: center;

      &:after {
        left: -units(3); } } } }
