@import 'styles/site.sass';

.Main {
  position: relative;
  background: $peach-200;
  color: $yolk;
  padding: $space-l 0 $space-m 0;

  @include min($screen-l) {
    padding-top: calc(#{$space-l} + #{$nav-bar-height});
    padding-bottom: $space-l; }

  header {
    position: relative;
    margin: 0 auto;
    @include min($screen-m) {
      display: flex;
      justify-content: space-between; }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      @include max($screen-l) {
        margin-top: units(8); } }

    h2 {
      @extend %body-l;
      max-width: units(70); } } }

.links {
  padding: $space-m 0;
  border-top: $border-width solid currentColor;
  // border-bottom: $border-width solid currentColor
  margin-top: $space-l;

  > h5 {
    @extend %heading-m;
    margin-bottom: units(5); }

  > div {
    display: grid;
    grid-gap: units(10);
    grid-template-columns: 1fr 1fr 1fr; } }
