@import 'styles/site.sass';

.Main {}

.content {
  // display: grid
  // grid-template-columns: calc(#{$left-column-width} - #{$space-m}) 1fr //$right-column-width
 }  // grid-column-gap: $space-m

.left {
  width: {} }
.main {
  position: relative;
  // padding-top: $space-m
 }  // max-width: $text-max-width
.feedback {}
