@import 'styles/site.sass';

.Main {
  position: absolute;
  top: calc(#{$nav-bar-height} - 1px);
  left: 0;
  width: units(55);
  background: $white;
  color: $black;
  cursor: pointer;
  padding: units(3) 0;
  box-shadow: 0 20px 20px rgba($black, 0.08);

  &.active {
    top: $nav-bar-active-height;
    background: $white; }

  ul {
    li a {
      height: units(7);
      padding: 0 units(6);
      @extend %body-s;
      @extend %medium;

      &:after {
        left: units(3); } } } }
