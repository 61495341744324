@import 'styles/site.sass';

.Main {
  background: $black;
  padding: units(15) 0;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end; } }
