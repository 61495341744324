@import 'styles/site.sass';

.Main {}

.pages {
  padding: $space-l 0;
  background: $grey-100;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end; } }
